// Overview
.nk-order-ovwg{
	&-ck{
		height: 180px;
	}
    &-data{
        padding: 0.75rem 1.25rem 1.25rem;
        border: 2px solid transparent;
        border-radius: $border-radius;
        .amount{
			font-size: 1.5rem;
			font-weight: $fw-bold;
			small{
				font-weight: $fw-normal;
			}
        }
        .info{
			font-size: $fx-sz-12;
			color: $base-light;
			margin-bottom: .25rem;
			strong{
				color: $base-color;
			}
        }
        .title{
			font-size: $fx-sz-14;
			line-height: 1.3;
			color: $base-light;
			font-weight: $fw-medium;
            .icon{
				font-size: 1rem;
				height: 28px;
				width: 28px;
				border-radius: 50%;
				background: $lighter;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				margin-right: 0.5rem;
            }
        }
        &.buy{
			border-color: mix($success, #fff, 50%);
			.amount,.title .icon{
				color: $success;
			}
        }
        &.sell{
			border-color: mix($accent-color, #fff, 50%);
			.amount,.title .icon{
				color: $accent-color;
			}
        }
    }
}
@include media-breakpoint-up(xxl) {
	.nk-order-ovwg{
		&-ck{
			height: 260px;
		}
	}
}

// Action
.nk-wg-action{
	display: flex;
	align-items: center;
	justify-content: space-between;
	&-content{
		position: relative;
		padding-left: 2rem;
		padding-right: .5rem;
		.icon{
			font-size: 1.5rem;
			left:0;
			position: absolute;
			color: $base-light;
		}
		.title{
			font-size: $fx-sz-14;
			font-weight: $fw-medium;
			padding-bottom: 0.25rem;
		}
		p{
			color: $base-light;
			font-size: $fx-sz-13;
			line-height: 1.25rem;
			strong{
				color: $accent-color;
			}
		}
	}
}

// coin overview 
.nk-coin-ovwg{
	display: flex;
	&-ck{
		flex-grow: 1;
		height: 165px;
	}
	&-legends{
		width: 100px;
		flex-shrink: 0;
		li{
			display: flex;
			align-items: center;
			line-height: 1.25rem;
			font-size: $fx-sz-12;
			padding: .25rem 0;
			color: $base-light;
			.dot{
				margin-right: 0.5rem;
			}
		}
	}
}

//order table
.nk-tb-orders-type{
	width: 66px;
}

// Sales data widgets
.nk-sale-data{
	flex-shrink: 0;
	&-group{
		display: flex;
		flex-wrap: wrap;
	}
	.amount{
		display: block;
		font-size: 1.75rem;
		color: $base-color;
		.change{
			font-size: .875rem;
		}
		&.sm{
			font-size: 1.125rem;
			color: $base-light;
		}
	}
	.sub-title{
		color: $base-light;
		font-size: $fx-sz-12;
		.change{
			margin-right: 0.5rem;
		}
	}
}

.nk-sales-ck{
	height: 80px;
	flex-grow: 1;
	&.large{
		height: 200px;
	}
}

@include media-breakpoint-between(lg,xxl){
	.nk-sales-ck{
		&.sales-revenue{
			height: 164px;
		}
	}
	
}

//Activity Widgets
.nk-activity{
	&-item{
		display: flex;
		align-items: center;
		padding: 1.25rem;
		&:not(:last-child){
			border-bottom: 1px solid $border-light;
		}
	}
	&-data{
		margin-left: 1rem;
		.time{
			display: block;
			font-size: $fx-sz-12;
			color: $base-light;
			line-height: 1.3;
		}
	}
}
@include media-breakpoint-up(sm){
	.nk-activity{
		&-item{
			display: flex;
			padding: 1rem 1.5rem;
		}
	}
}

//Activity Widgets
.nk-support{
	&-item{
		display: flex;
		padding: 1.25rem;
		&:not(:last-child){
			border-bottom: 1px solid $border-light;
		}
	}
	&-content{
		flex-grow: 1;
		margin-left: 1rem;
		.title{
			font-size: 0.875rem;
			font-weight: $fw-bold;
			color:$base-color;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		p{
			margin-bottom: 0;
			margin-top: 0.25rem;
		}
		.time{
			display: block;
			font-size: $fx-sz-12;
			color: $base-light;
		}
	}
}
@include media-breakpoint-up(sm){
	.nk-support{
		&-item{
			display: flex;
			padding: 1.25rem 1.5rem;
		}
	}
}