.border{
    &-transparent{
        border-color: transparent;
    }
}

.border-dashed {
    border-style: dashed !important
}

.border-dotted {
    border-style: dotted !important
}