.link{
    display: inline-flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: $fw-medium;
    font-family: $base-font-family;
    line-height: 1;
    border:none;
    background: transparent;
    transition: all .3s ease;
    white-space: nowrap;
    &:focus{
        box-shadow: none;
        outline: none;
    }
    .icon{
        font-size: 1.3em;
    }
    span,.icon{
        display: inline-block;
    }
    .icon-circle{
        display: inline-flex;
    }
    .icon + span, span + .icon{
        padding-left: 10px;
    }
    &-md{
        font-size: 1.25rem;
    }
    &-sm{
        font-size: .75rem;
    }
    &-dim {
    	opacity: .7;
    	&:hover {
    		opacity: 1;
    	}
    }
    &-block{
        display: flex;
    }
    &-between{
        justify-content: space-between;
    }
}

$link-colors: map-merge(
    $theme-colors,
    (
        "white":       $white,
        "light":       $base-light,
        "lighter":     $light-400,
        "text":        $base-text
    )
);

@each $state, $color in $link-colors {
    .link-#{$state}{
        color:$color !important;
    }
    .link-on-#{$state}:hover{
        color:$color !important;
    }
}

.link {
	&-danger, &-warning, &-success, &-info {
		&:hover{
		    color: $base-text !important;
		}
	}
}