/*! Email Template */
@import "_dashlite_variables";
.email{
    &-wraper{
        background: $lighter;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color:$base-light;
        width: 100%;
        a{
            color: $accent-color;
            word-break: break-all;
        }
		.link-block {
			display: block;
		}
    }
    &-ul{
        margin:5px 0;
		padding: 0;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
        li{
            list-style: disc;
            list-style-position:inside;
        }
		&-col2 {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				padding-right: 10px;
			}
		}
    }
    &-body{
        width:96%;
        max-width:620px;
        margin:0 auto;
        background:#ffffff;
    }
    &-success{
        border-bottom: $success;
    }
    &-warning{
        border-bottom: $warning;
    }
    &-btn{
        background:$accent-color;
        border-radius:4px;
        color:#ffffff !important;
        display:inline-block;
        font-size:13px;
        font-weight:600;
        line-height:44px;
        text-align:center;
        text-decoration:none;
        text-transform: uppercase; 
        padding: 0 30px;
        &-sm{
            line-height:38px;
        }
    }
    &-header,&-footer{
        width:100%;
        max-width:620px;
        margin:0 auto;
    }
    &-logo{
        height: 40px;
    }
    &-title{
        font-size: 13px; 
        color: $accent-color; 
        padding-top: 12px;
    }
    &-heading{
        font-size: 18px;
        color: $accent-color;
        font-weight: 600;
        margin: 0;
        line-height: 1.4;
        &-sm{
            font-size: 24px;
            line-height: 1.4;
            margin-bottom: .75rem; 
        }
        &-s1 {
            font-size: 20px; 
            font-weight: 400;
            color: $base-text;
        }
        &-s2{
            font-size: 16px; 
            color: #526484; 
            font-weight: 600;
            margin: 0; 
            text-transform: uppercase; 
            margin-bottom: 10px
        }
        &-s3{
            font-size: 18px; 
            color: $accent-color; 
            font-weight: 400; 
            margin-bottom: 8px;
        }
        &-success{
            color: $success;
        }
        &-warning{
            color: $warning;
        }
    }
    &-note{
        margin: 0; 
        font-size: 13px; 
        line-height: 22px; 
        color: $base-light;
    }
    &-copyright-text{
        font-size: 13px;
    }
    &-social{
        li{
            display: inline-block;
            padding: 4px;
            a{
                display: inline-block; 
                height: 30px; 
                width:30px;
                border-radius: 50%; 
                background: #ffffff;
                img{
                    width: 30px;
                }
            }
        }
    }
}
@media (max-width:480px) {
	.email-preview-page .card {
		border-radius: 0;
		margin-left: -20px;
		margin-right: -20px;
	}
	.email-ul-col2 li {width: 100%;}
}