.nk-editor{
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - (#{$header-height} + 78px + #{$body-offset*2}));
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.125rem 1.5rem;
        border-bottom: 1px solid $border-light;
    }
    &-title{
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-tools{
        display: flex;
        align-items: center;
    }
    &-main{
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
    }
    &-body{
        flex-grow: 1;
        font-family: 'Nunito';
        line-height: 1.4;
        font-size: 0.875rem;
        color:#556581;
        table {
            border-collapse: collapse;
        }
        table td,
        table th {
            border: 1px solid #ccc;
            padding: 0.4rem;
        }
        figure {
            display: table;
            margin: 1rem auto;
        }
        figure figcaption {
            color: #999;
            display: block;
            margin-top: 0.25rem;
            text-align: center;
        }
        hr {
            border-color: #ccc;
            border-style: solid;
            border-width: 1px 0 0 0;
        }
        code {
            background-color: #e8e8e8;
            border-radius: 3px;
            padding: 0.1rem 0.2rem;
        }      
        h1{
            font-size: 2em;
            font-weight: bold;    
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            color: inherit !important;
        }
        h2{
            font-size: 1.5em;
            font-weight: bold;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            color: inherit !important;
        }
        h3{
            font-size: 1.17em;
            font-weight: bold;
            margin-block-start: 1em;
            margin-block-end: 1em;
            color: inherit !important;
        }
        h4{
            font-size: 1em;
            font-weight: bold;
            margin-block-start: 1.33em;
            margin-block-end: 1.33em;
            color: inherit !important;
        }
        h5{
            font-size: .83em;
            font-weight: bold;
            margin-block-start: 1.67em;
            margin-block-end: 1.67em;
            color: inherit !important;
        }
        h6{
            font-size: .67em;
            font-weight: bold;
            margin-block-start: 2.33em;
            margin-block-end: 2.33em;
            color: inherit !important;
        }
        p{
            font-size: 1em;
            margin-block-start: 1em;
            margin-block-end: 1em;
            color: inherit !important;
        }
    }
    &-style-clean{
        .tox-tinymce{
            border:none !important;
        }
        .tox .tox-toolbar, .tox .tox-toolbar__primary, .tox .tox-toolbar__overflow{
            border-bottom: 0 !important;
        }
        .tox{
            .tox-toolbar__group:not(:last-of-type){
                border-right: 0 !important;
            }
            .tox-statusbar{
               display: none !important;
            }
            .tox-editor-header{
                padding: .375rem .75rem 0 !important;
            }
            .tox-edit-area{
                padding: 0 .625rem 0 !important;
            }
        }
    }
    &-full{
        .tox{
            min-height: 400px;
            height: 100% !important;
        }
    }
    &-base{
        width: 100%;
        border-bottom: 1px solid $border-light;
    }
}
.tox-notifications-container{
    display: none !important;
}

@include media-breakpoint-up(xl){
    .nk-editor{
        &-main{
            flex-direction: row-reverse;
            flex-wrap: nowrap;
        }
        &-base{
            flex-shrink: 0;
            width: 360px;
            border-left: 1px solid $border-light;
            border-bottom: 0;
        }
    }
}

@include media-breakpoint-up(xl){
    .nk-editor{
        &-title{
            justify-content: start;
        }
    }
}

@include media-breakpoint-down(xl){
    .nk-editor{
        min-height: calc(100vh - (#{$header-height} + 78px + #{$body-offset}));
    }
}
@include media-breakpoint-down(sm){
    .nk-editor{
        min-height: calc(100vh - (#{$header-height} + 116px + #{$body-offset}));
    }
}